@use 'sass:map';

/*****************************************************************************************************************************************************
 ** COMMON
 ****************************************************************************************************************************************************/
$options: (
	'grid': true,
	'print-styles': true,

	'rounded': true,
	'shadows': false,
	'gradients': false,
	'transitions': true,
);

$baseSpacer: 0.5rem; // 8px
$baseSpacerPx: 8px;

/*****************************************************************************************************************************************************
 ** SPACING
 ****************************************************************************************************************************************************/
$baseGutterWidth: $baseSpacer * 3;

$spacers: (
	0: 0,
	1: $baseSpacer * 0.25,
	2: $baseSpacer * 0.5,
	3: $baseSpacer,
	4: $baseSpacer * 1.5,
	5: $baseSpacer * 3,
	6: $baseSpacer * 6,
	7: $baseSpacer * 8,
	8: $baseSpacer * 10,
	9: $baseSpacer * 15,
);
$gutters: $spacers;

/*****************************************************************************************************************************************************
 ** BREAKPOINTS, CONTAINERS, GRID
 **
 ** WARNING: Adding or removing breakpoints also requires code changes in TypeScript, but breakpoint values can be changed without issue.
 ****************************************************************************************************************************************************/
$breakpoints: (
	'xs': 0,
	'sm': 576px,
	'md': 768px,
	'lg': 960px,
	'xl': 1150px,
	'xxl': 1500px,
	'xxxl': 1900px,
	'uwide': 2600px,
	'suwide': 3500px,
);

$columns: 12;
$containers: (
	'max-width': (
		'xs': map.get($breakpoints, 'sm') - ($baseSpacerPx * 2) * 1.5,
		'sm': map.get($breakpoints, 'md') - ($baseSpacerPx * 2) * 1.5,
		'md': map.get($breakpoints, 'lg') - ($baseSpacerPx * 2) * 3,
		'lg': map.get($breakpoints, 'xl') - ($baseSpacerPx * 2) * 4.5,
		'xl': map.get($breakpoints, 'xxl') - ($baseSpacerPx * 2) * 6,
		'xxl': map.get($breakpoints, 'xxxl') - ($baseSpacerPx * 2) * 8,
		'xxxl': 2000px,
		'uwide': 2100px,
		'suwide': 2600px,
	),
	'padding': $baseSpacer,
);
$fluidContainers: (
	'max-width': map.get($containers, 'max-width', 'xxxl'),
);

/*****************************************************************************************************************************************************
 ** THEMES
 ****************************************************************************************************************************************************/
$fontBuda: 'Buda', sans-serif;
$fontRoboto: 'Roboto', sans-serif;

$baseLineHeight: 1.5;
$standardFontConfiguration: (
	'base': (
		'font-family': $fontRoboto,
		'font-size': 1.4rem,
	),

	'lead': (
		'font-size': 1.8rem,

		'xs': (
			'font-size': 1.5rem,
		),
		'sm': (
			'font-size': 1.6rem,
		),
		'md': (
			'font-size': 1.7rem,
		),
	),

	'h1': (
		'font-family': $fontBuda,
		'font-size': 4.5rem,
		'padding-bottom': map.get($spacers, 5),

		'xs': (
			'font-size': 4rem,
		),
	),
	'h2': (
		'font-family': $fontBuda,
		'font-size': 3.5rem,
		'padding-bottom': map.get($spacers, 5),

		'xs': (
			'font-size': 3rem,
		),
	),
	'h3': (
		'font-size': 2.5rem,
		'padding-bottom': map.get($spacers, 4),

		'xs': (
			'font-size': 2rem,
		),
	),
	'h4': (
		'font-size': 2rem,
		'padding-bottom': map.get($spacers, 3),
	),
	'h5': (
		'font-size': 1.5rem,
		'padding-bottom': map.get($spacers, 2),
	),
	'h6': (
		'font-size': 1.4rem,
		'padding-bottom': map.get($spacers, 2),
	),
);

$rawColors: (
	'white': #fff,
	'black': #000,
	'prussian-blue': #023047,
	'rich-black': #051923,
	'onyx': #343a40,
	'tangerine': #fb8500,
	'alice-blue': #ebf5ff,
);

$baseBodyBackgroundColor: map.get($rawColors, 'rich-black');
$themes: (
	'primary': (
		'font': $standardFontConfiguration,
		'palette': (
			'background': map.get($rawColors, 'prussian-blue'),
			'text': (
				'base': map.get($rawColors, 'white'),
				'link': map.get($rawColors, 'tangerine'),
				'link-hover': map.get($rawColors, 'alice-blue'),
			),
		),
	),
	'secondary': (
		'font': $standardFontConfiguration,
		'palette': (
			'background': map.get($rawColors, 'rich-black'),
			'text': (
				'base': map.get($rawColors, 'white'),
				'link': map.get($rawColors, 'white'),
				'link-hover': map.get($rawColors, 'tangerine'),
			),
		),
	),
	'tertiary': (
		'font': $standardFontConfiguration,
		'palette': (
			'background': map.get($rawColors, 'onyx'),
			'text': (
				'base': map.get($rawColors, 'white'),
				'link': map.get($rawColors, 'white'),
				'link-hover': map.get($rawColors, 'tangerine'),
			),
		),
	)
);

/*****************************************************************************************************************************************************
 ** BOOTSTRAP TRANSLATION
 ****************************************************************************************************************************************************/
$enable-rounded: map.get($options, 'rounded');
$enable-shadows: map.get($options, 'shadows');
$enable-gradients: map.get($options, 'gradients');
$enable-transitions: map.get($options, 'transitions');
$enable-grid-classes: map.get($options, 'grid');
$enable-print-styles: map.get($options, 'print-styles');

$container-max-widths: map.get($containers, 'max-width');
$container-padding-x: map.get($containers, 'padding');

$grid-breakpoints: $breakpoints;
$grid-columns: $columns;
$grid-gutter-width: $baseGutterWidth;

$body-bg: $baseBodyBackgroundColor;

$font-family-base: map.get($standardFontConfiguration, 'base', 'family');
$font-size-base: map.get($standardFontConfiguration, 'base', 'size');
$line-height-base: $baseLineHeight;
