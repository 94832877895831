@use 'sass:map';
@use 'sass:math';

@mixin rotateUtilities($degreeInterval: 45) {
	// Validate inputs
	@if $degreeInterval < 1 or $degreeInterval > 359 {
		@error 'Degree interval must be greater than 0 and less than 360';
	}

	@for $i from 0 to 359 {
		// Only write classes if $i is a multiple of the interval (or 0)
		@if $i % $degreeInterval == 0 {
			.rotate-#{$i} {
				transform: rotate(#{$i}deg);
			}
			.rotate-x-#{$i} {
				transform: rotateX(#{$i}deg);
			}
			.rotate-y-#{$i} {
				transform: rotateY(#{$i}deg);
			}
		}
	}
}

@mixin scaleUtilities($interval: 10, $min: 10, $max: 99, $type: 'percent') {
	// Validate inputs
	@if $min < 1 {
		@error 'Minumum must be greater than or equal to 1';
	}
	@if $interval < 1 {
		@error 'Interval must be greater than or equal to 1';
	}
	@if $min > $max {
		@error 'Minumum cannot be greater than maximum';
	}
	@if $interval % 1 != 0 {
		@error 'Interval must be an integer';
	}
	@if $min % 1 != 0 {
		@error 'Minimum must be an integer';
	}
	@if $max % 1 != 0 {
		@error 'Maximum must be an integer';
	}
	@if $type != 'percent' and $type != 'factor' {
		@error 'Unknown scale type';
	}

	$next: $min;
	@while ($next <= $max) {
		$scaleFactor: math.div($next, 100);

		// Set label for class name
		$label: '';
		@if $type == 'percent' {
			$label: $next;
		}
		@if $type == 'factor' {
			// Ensure an integer
			@if $next % 100 != 0 {
				@error 'Factor-based scale values must be cleanly divisible by 100';
			}

			$label: '#{$scaleFactor}x';
		}

		// Skip writing scale-down for factor-based numbers since it doesn't make sense
		@if $type != 'factor' {
			.scale-down-#{$label} {
				transform: scale(1 - ($scaleFactor));
			}
			.scale-x-down-#{$label} {
				transform: scale(1 - ($scaleFactor), 1);
			}
			.scale-y-down-#{$label} {
				transform: scale(1, 1 - ($scaleFactor));
			}
		}

		.scale-x-up-#{$label} {
			transform: scale(1 + ($scaleFactor), 1);
		}
		.scale-up-#{$label} {
			transform: scale(1 + ($scaleFactor));
		}
		.scale-y-up-#{$label} {
			transform: scale(1, 1 + ($scaleFactor));
		}

		$next: $next + $interval;
	}
}
