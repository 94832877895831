@use 'sass:map';
@use 'sass:math';  ;

//noinspection SassScssResolvedByNameOnly
@mixin writeProperties($baseDefinition, $specificDefinition: (), $breakpoint: null) {
	// Inject all properties defined in the base list, if it's a map
	@if type-of($baseDefinition) == 'map' {
		@each $property, $value in $baseDefinition {
			// Call this function recursively if the property is a breakpoint with its own list of properties
			@if map.has-key($breakpoints, $property) {
				@include writeProperties($value, map.get($specificDefinition, $property), $property);
			}
			// Otherwise write the property/value pair
			@else {
				// Use the specific value, if defined
				$specificValue: map.get($specificDefinition, $property);
				@if $specificValue != null {
					$value: $specificValue;
				}

				@include writeProperty($property, $value, $breakpoint);
			}
		}
	}

	// Inject all properties defined in the specific list, if it's a map
	@if type-of($specificDefinition) == 'map' {
		@each $property, $value in $specificDefinition {
			// Call this function recursively if the property is a breakpoint with its own list of properties
			@if map.has-key($breakpoints, $property) {
				@include writeProperties(map.get($baseDefinition, $property), $value, $property);
			}
			// Skip any properties that are in the base definition, because they would have already been included
			@else if type-of($baseDefinition) != 'map' or not map.has-key($baseDefinition, $property) {
				@include writeProperty($property, $value, $breakpoint);
			}
		}
	}
}

//noinspection SassScssResolvedByNameOnly
@mixin writeProperty($property, $value, $breakpoint: null) {
	// Wrap breakpoint media query around property/value, if defined
	@if $breakpoint != null {
		@include media-breakpoint-only($breakpoint) {
			#{$property}: #{$value};
		}
	}
	// Otherwise just write the property/value
	@else {
		#{$property}: #{$value};
	}
}
