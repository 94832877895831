@use 'sass:map';

// Flip/invert utilities
.flip-x {
	transform: scale(-1, 1);
}
.flip-y {
	transform: scale(1, -1);
}
.flip-xy {
	transform: scale(-1, -1);
}

// Full-width images (in their containers)
img {
	width: 100%;
}

// List styles
ul.list-style-circle { // TODO Refactor this?
	list-style-type: circle;
}

//noinspection SassScssResolvedByNameOnly
@if $fluidContainers {
	.container-fluid {
		$maxWidth: map.get($fluidContainers, 'max-width');

		@if $maxWidth != null {
			max-width: $maxWidth;
		}
	}
}
