@use 'sass:map';
@import 'variables.module';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins/breakpoints';

@import 'utility/mixin-abstract';
@import 'utility/mixin-themes-and-colors';
@import 'utility/mixin-transforms';

@import 'utility/bs-cleanup';
@import 'utility/general';

/*****************************************************************************************************************************************************
 ** UTILITY // COLORS, THEMES
 ****************************************************************************************************************************************************/
@include colorUtilitiesFromRawColors($rawColors);
@include themesFromThemes($themes);

/*****************************************************************************************************************************************************
 ** UTILITY // TRANSFORMS
 ****************************************************************************************************************************************************/
@include rotateUtilities();
@include scaleUtilities();
@include scaleUtilities(100, 100, 500, 'factor');

.logo {
	width: 80%;
	max-width: 1000px;
}

.icon-quote-start {
	margin-right: 10px;
	margin-bottom: 15px;
}
.icon-quote-end {
	margin-left: 10px;
	margin-top: -15px;
}
